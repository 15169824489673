import React,{Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './amin.css';
import { Modal,Button,OverlayTrigger,Tooltip } from 'react-bootstrap';
import moment from "moment";

class AdminZone extends Component {
	 constructor(props) {
    super(props);
    this.state = {
			tableData:[],
			newPayment : false,
			new_request_uuid : null,
			cancel_modal_visible : false,
			request_to_be_canceled : null
   	}
	}
	componentDidMount(){
		this.getList();
	}
	getList = ()=>{
		fetch("/payments-list" , {
			method: 'GET',
	      headers: {
	        "Content-Type": "application/json"
	      }
	  		 })
			 .then((response) => response.json())
			 .then(
	        (res) => {
	        	if (res.status==="ERROR") {
					alert("error")
				}
				if (res.status==="OK") {
					this.setState({tableData : res.data.map((r)=>{return {...r,payed:r.payed===false ? "NO":"YES" } })  })
				}
	        })
	}
	handleClose(){
		this.setState({newPayment : false})
	}
	newPaymentRequest=(ev)=>{
		ev.preventDefault();
		let payload = {
			description : ev.target.description.value,
			currency : ev.target.currency.value,
			amount : Number(ev.target.amount.value)*100
		}
		fetch("/payment-request" , {
			method: 'POST',
			credentials: 'include',
	      headers: {
	        "Content-Type": "application/json"
	      },
			body : JSON.stringify(payload)
	  		 })
			 .then((response) => response.json())
			 .then(
	        (res) => {
	        	if (res.status==="ERROR") {
					alert("error")
				}
				if (res.status==="OK") {
					this.showUUID(res.data);
					console.log(res.data)
				}
	        })
	}
	showUUID(data){
		console.log(data);
		this.setState({ "new_request_uuid" : window.location.href+"payment/"+data.uuid });
		let tableData = [...this.state.tableData];
		tableData.unshift(data)
		this.setState({tableData : tableData })
	}
	setAmount=(ev)=>{
		let a = ev.target.value;
		if (a.indexOf(",")!==-1) {
			a = a.replace(",",".");
		}
		if (a.indexOf(".")===-1) {
			a = a+".00";
		}
		ev.target.value = Number(a).toFixed(2);
	}
	cancelRequestModal(recnum){
		this.setState({cancel_modal_visible : true, request_to_be_canceled : recnum })
	}
	handleCloseCancelDialog=()=>{
		this.setState({cancel_modal_visible : false,request_to_be_canceled : null })
	}
	cancelRequest=()=>{
		fetch("/cancel-request" , {
			method: 'POST',
			credentials: 'include',
	      headers: {
	        "Content-Type": "application/json"
	      },
			body : JSON.stringify({recnum: this.state.request_to_be_canceled})
	  		 })
			 .then((response) => response.json())
			 .then(
	        (res) => {
	        	if (res.status==="ERROR") {
					alert("error")
				}
				if (res.status==="OK") {
					console.log(res.recnum);
					let data = [...this.state.tableData];
					let i = data.findIndex((r)=>{ return r.recnum === res.recnum });
					data[i].status = "cancelled";
					console.log(i, data)
					this.setState({tableData : data })

				}
	        })
		this.setState({cancel_modal_visible : false,request_to_be_canceled : null })
	}
	render(){
		const columns = [
		{
		    Header: '#',
		    accessor: 'recnum',
		    width : 80,
		  },
		{
		    Header: 'Description',
		    accessor: 'description'
		  },
		{
		    Header: 'Amount',
		    accessor: 'amount',
		    width : 200,
		    Cell : props => Number(props.value/100).toFixed(2)
		},
		 {
		    Header: 'Currency',
		    accessor: 'currency',
		    width : 100,
		    Cell : props => props.value.toUpperCase()
		  },
		  {
		    Header: 'Status',
		    accessor: 'status',
		    width: 100,
		    Cell : props => props.value[0].toUpperCase() + props.value.slice(1)
		},{
		    Header: 'Link',
		    accessor: 'uuid',
		    width :50,
		    Cell : props => <OverlayTrigger
        							key={"bottom"}
        							placement={"bottom"}
        							overlay={
          							<Tooltip id={`tooltip-bottom`}>
            						Copy to clipboard
          						</Tooltip>
        							}
      							>
      								<CopyToClipboard text={window.location.href+"payment/"+props.value} >
					          			<button type="button" className="btn btn-primary btn-sm"><i className="fa fa-paste"/></button>
			        					</CopyToClipboard>
			        				</OverlayTrigger>
		},
		{
		    Header: 'Stripe ID',
		    accessor: 'pid',
		    width:300
		  },
			{
			    Header: 'Request created',
			    accessor: 'request_created',
			    width:200,
					 Cell : props => moment(props.value).format("DD.MM.YYYY HH:mm")
			  },
		{
		    Header: '',
		    accessor: 'recnum',
		    width : 82,
		    Cell : props => <button className="btn btn-secondary btn-sm" onClick={ () => this.cancelRequestModal(props.value) } id={props.value}>Cancel</button>
		}];
    return (
<div className="App">
		<nav className="navbar navbar-light bg-light mb-2">
		  <a className="navbar-brand">Maurice Ward Group</a>
		  <button className="btn btn-outline-success my-2 my-sm-0" type="button" onClick={ this.props.signOff }>
				<i className="fa fa-sign-out-alt"/>
		  </button>
		</nav>
	<div className="container-fluid">
	<button className="btn btn-primary" onClick={ ()=>this.setState({newPayment : true, new_request_uuid : null }) }>Request payment</button>
	<button className="btn btn-secondary float-right" onClick={ this.getList }><i className="fa fa-sync" /></button>
	  <ReactTable
	  	 className="-highlight mt-3"
	    data={this.state.tableData}
	    style={{height:"calc(80%-100px)"}}
	    columns={columns}
	  />
      <Modal show={this.state.newPayment} onHide={()=>this.handleClose()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Payment request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
				<form onSubmit={ this.newPaymentRequest }>
					<div className="form-group">
						<label className="control-label">Description</label>
						<input type="text" name="description" className="form-control"/>
					</div>
					<div className="form-group">
						<label className="control-label">Currency</label>
						<select className="form-control" name="currency">
							<option value="eur">EUR</option>
						</select >
					</div>
					<div className="form-group">
						<label className="control-label">Amount</label>
						<input type="text" name="amount" className="form-control" onBlur={ this.setAmount }  />
					</div>
		          <button className="btn btn-primary" type="submit">
		            Place request
		          </button>
		          { this.state.new_request_uuid !==null && (
		          	<div>
		          	<p className="mt-3 mb-1">Request registered, your link is </p>
						<p className="mt-0 alert alert-info mb-1">{this.state.new_request_uuid}</p>
			        <CopyToClipboard text={this.state.new_request_uuid}
			          onCopy={() => this.setState({copied: true})}>
			          <button type="button" className="btn btn-primary"><i className="fa fa-paste"/></button>
			        </CopyToClipboard>
						</div>
		          )}
        		</form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>this.handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* dialog pro zruseni */}
      <Modal show={this.state.cancel_modal_visible} onHide={this.handleCloseCancelDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel request #{ this.state.request_to_be_canceled} ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure ? Link for payment will be not valid anymore.</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={this.cancelRequest}>
            Yes
          </Button>
          <Button variant="secondary" onClick={this.handleCloseCancelDialog}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
</div>
    );
 	}
};

export default AdminZone;
