import React, { Component } from "react";

import '@fortawesome/fontawesome-free/css/all.css';
import api from "./api";
import Login from "./login";
import "./App.css";
import logo from "./group_logo.png";
import AdminZone from "./admin.js";
import PaymentGate from "./paymentgate";


class App extends Component {
	constructor(props){
		super(props);
		this.onLoginSuccess = this.onLoginSuccess.bind(this);
		this.state = {
			loged : false,
			show_login : false,
			show_payment_form : false,
			error_msg : null,
			payments_data : null,
			show_email_prompt : false,
			mail_input_visible : false
		}
		if (window.location.href.indexOf("payment/")!==-1) {
			api.findPaymentRequest({uuid : window.location.href.slice(window.location.href.lastIndexOf("/")+1)}).then((res)=>{
				if (res.status==="OK") {
					this.setState({
							show_email_prompt : true,
							payments_data : res.data
						})
					window.history.pushState("", "", '/');
				}
				else {
					this.setState({error_msg:res.msg});
				}
			})			
		}
	}
	findPayment = (ev)=>{
		ev.preventDefault();
		api.findPaymentRequest({uuid : ev.target.uuid.value}).then((res)=>{
			if (res.status==="OK") {
				this.setState({
					show_email_prompt : true,
					payments_data : res.data
				})
			}
			else {
				this.setState({error_msg : res.msg })
			}
		})
	}
	onLoginSuccess(data){
		this.setState({
			show_login : false,
			loged : true
		})	
	}
	mailcheckbox = (ev)=>{
		this.setState({ mail_input_visible : ev.target.checked })	
	}
	mailPromptSubmit=(ev)=>{
		ev.preventDefault()
		this.setState({
			mail_prompt : {
				send_confirm : ev.target.send_mail_confirm.checked,
				confirm_mail : ev.target.send_mail_confirm.checked === true ? ev.target.email.value :null
			},
			show_payment_form : true
		})	
	}
	toggleLoginPage=()=>{
		this.setState({show_login : !this.state.show_login })
	}
	signOff=()=>{
		this.setState({
			loged : false
		})		
	}
render(){	

	if (this.state.loged===true) {
		return(<AdminZone signOff={this.signOff}/>)
	}
	else {
  return (
    <div className="App">
		<nav className="navbar navbar-light bg-light">
		  <a className="navbar-brand">Maurice Ward Group</a>
		  <button className="btn btn-outline-success my-2 my-sm-0" type="button" onClick={ this.toggleLoginPage }>
				<i className="fa fa-user"/>		  
		  </button>
		</nav>
      <div className="sr-root">
        <div className="sr-main">
          <header className="sr-header">
            <img className="mwlogo" src={logo}/>
          </header>
          {this.state.show_payment_form===true ? (
					<PaymentGate payments_data={this.state.payments_data} mail_prompt={this.state.mail_prompt} />
          ) : 	this.state.show_login===true ? ( <Login onLoginSuccess={this.onLoginSuccess}/> )
				: (
          	<div>
          		{this.state.show_email_prompt===false ? (
          		<form onSubmit={ this.findPayment }>
          			<input type="text" name="uuid" placeholder="Enter payment ID" className="form-control"/>
          			<button type="submit" className="btn btn-primary col-12 mt-2" placeholder="Enter payment ID">
          				Next
          			</button>
          			{this.state.error_msg !==null && (<div className="alert alert-danger mt-2">{this.state.error_msg}</div>)}
          		</form>
          		):(
          		<form onSubmit={ this.mailPromptSubmit }>
          			<h4>Payment details</h4>
          			<div className="form-group mt-2 mb-1">
          				<label >Description : </label>
          				<span className="font-weight-bold"> {this.state.payments_data.description }</span>
          			</div>
          			<div className="form-group mt-1 mb-1">
          				<label >Amount : </label>
          				<span className="font-weight-bold"> { Number(this.state.payments_data.amount/100).toFixed(2) } {this.state.payments_data.currency.toUpperCase() }</span>
          			</div>
          			<div className="form-group mt-2 mb-2">
	          			<label className="checkbox" >
   	       			<input  type="checkbox" name="send_mail_confirm" onChange={ this.mailcheckbox }/>
      	    			<span className="primary"></span>
         	 			</label>
          				<label className="control-label">Send confirmation to email</label>	
          				</div>
	          		{ this.state.mail_input_visible === true && (<input type="text" className="form-control mb-2" name="email" placeholder="Enter email"/>)}
	          		
          			<button type="submit" className="btn btn-primary col-12">
          				Next
          			</button>          			
          		</form>
          		)}
          	</div>
          )}
        </div>
      </div>
    </div>
  );}
  }
}
export default App;