import React,{Component } from 'react';
//const manifest = require("./manifest.json")
class Login extends Component {
	
	 constructor(props) {
    super(props);	
    this.loginHandle = this.loginHandle.bind(this);
    this.state = {
		loginError : '',
		readOnlyView : false,
		shipment : { dimensions : [], comments : [],filesnames:[] },
		token : null,
		version : 0.1
   	}
	}
 loginHandle(e){
 	e.preventDefault();
	fetch("/sign-in" , {   
		method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
		body : JSON.stringify({"username":e.target.username.value,"password": e.target.password.value})
  		 })
		 .then((response) => response.json())
		 .then(
        (res) => {
        	if (res.status==="ERROR") {
				this.setState({ loginError : res.message	})
			}
			if (res.status==="OK") {
				this.props.onLoginSuccess(res);
		//		this.setState({ userLoged:true });
			}
        })
 }
	render(){	
    return (
        <form  onSubmit={ this.loginHandle }>
                    <div className="form-group has-danger">
                        <label className="sr-only" for="email">E-Mail Address</label>
                        <div className="input-group">
                            <div className="input-group-prepend" ><i className="input-group-text fa fa-user"></i></div>
                            <input type="text" name="username" className="form-control" id="email"
                                   placeholder="Username" required autofocus autocomplete="off" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="sr-only" for="password">Password</label>
                        <div className="input-group">
                            <div className="input-group-prepend" style={{width: 2.6+"rem"}}><i className="input-group-text fa fa-key"></i></div>
                            <input type="password" name="password" className="form-control" id="password"
                                   placeholder="Password" required/>
                        </div>
                    </div>
                    <div className="form-control-feedback">
                        <span className="text-danger align-middle">
                           { this.state.loginError }
                        </span>
                    </div>
            <div className="d-flex justify-content-center" >
                    <button type="submit" className="btn btn-primary col-12" ><i className="fa fa-sign-in"></i> Login</button>
                </div>
        </form>
    );
 	}
};

export default Login;