import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DemoText from "./components/DemoText";
import CheckoutForm from "./components/CheckoutForm";
import api from "./api";
const stripePromise = api.getPublicStripeKey().then(key => loadStripe(key));
class paymentGate extends Component {
	constructor(props){
		super(props);
		this.state = {
			payments_data : props.payments_data,
			mail_prompt : props.mail_prompt
		}
		}
	render(){
		
		return(
       <Elements stripe={stripePromise}>
         <CheckoutForm payments_data={this.state.payments_data} mail_prompt={this.state.mail_prompt}  />
       </Elements>
       )
   }
 }
 export default paymentGate;